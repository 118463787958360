import type { AppUrl } from "@tamarack/shared/AppUrl";

export async function remixEndpointFetcher<K extends (...args: any) => any>(
  input: AppUrl,
  init?: RequestInit
): Promise<Awaited<ReturnType<K>> | undefined> {
  const response = await fetch(input.toString(), {
    ...init,
    /**
     * Don't follow the redirects that are thrown in Remix
     */
    redirect: "error",
  });

  if (response.ok) {
    try {
      /**
       * Attempt to parse as json. This can fail if it's not json, which can happen if the endpoint
       * has a UI.
       */
      const data = await response.json();
      return data as Awaited<ReturnType<K>>;
    } catch (e) {
      if (import.meta.env.MODE !== "test") {
        console.error("Unable to parse json", e);
      }

      throw response;
    }
  } else {
    throw response;
  }
}
