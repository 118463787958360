import type { PermissionType } from "@tamarack/sdk";
import { responseError } from "@tamarack/shared/errors";
import { ApiPermissionsLoader } from "../../routes/api.permissions/route";
import { getApiPermissionsUrl } from "../../routes/api.permissions/utils";
import { remixEndpointFetcher } from "../../utils/remixEndpointFetcher";
import AppStore from "./AppStore";

const permissionStore = new AppStore<Record<PermissionType, boolean>>("permissions", async () => {
  try {
    const data = await remixEndpointFetcher<ApiPermissionsLoader>(getApiPermissionsUrl());
    const indexedPermissions = {} as Record<PermissionType, boolean>;

    if (!data?.permissions) {
      return;
    }

    for (const permission of data?.permissions) {
      indexedPermissions[permission] = true;
    }

    return indexedPermissions;
  } catch (e: unknown) {
    if (e instanceof Response) {
      responseError("Error loading permissions store", e, {
        status: e.status,
        headers: e.headers,
        text: await e.text(),
      });
    } else {
      responseError("Error loading permissions store", e);
    }

    return undefined;
  }
});

export default permissionStore;
