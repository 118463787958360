export default class AppStore<T extends object> {
  key: string;
  fetcher: () => Promise<T | undefined>;
  cached = false;

  constructor(key: string, fetcher: () => Promise<T | undefined>) {
    this.key = key;
    this.fetcher = fetcher;
    this.cached = !!window.localStorage.getItem(this.key);
  }

  async load() {
    /**
     * Emergency catch to make sure the app doesn't crash. Ideally, the fetcher should handle
     * these errors
     */
    try {
      const data = await this.fetcher();
      await this.set(data);
    } catch (e) {
      console.error(
        "Error loading store: ",
        this.key + ".",
        "You should catch these errors when defining the fetcher for the store.",
        e
      );
    }
  }

  async get(): Promise<T | undefined> {
    /**
     * Load the data if not cached yet
     */
    if (!this.cached) {
      await this.load();
    }

    return this.getFromStorage();
  }

  getFromStorage() {
    /**
     * Return the cached data
     */
    try {
      const rawData = window.localStorage.getItem(this.key);

      if (!rawData) {
        return undefined;
      }

      const data = JSON.parse(rawData);
      return data as T;
    } catch (e) {
      return undefined;
    }
  }

  set(newValue?: T) {
    window.localStorage.setItem(this.key, JSON.stringify(newValue));

    if (newValue !== undefined) {
      this.cached = true;
    }
  }

  remove() {
    window.localStorage.removeItem(this.key);
    this.cached = false;
  }
}
