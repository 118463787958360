import { json } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { isApiResponseError } from "@tamarack/shared/ApiResponseError";
import { getErrorMessage } from "./getErrorMessage";

export type ErrorResponse<T = object> = T & {
  error: string;
  forceLogin?: boolean;
  status?: number;
};

export function getErrorStatus(response: any) {
  return +(response?.status ?? 500);
}

export function getResponseObject(error: any) {
  if (!error) {
    return "";
  }

  if (isApiResponseError(error)) {
    return {
      message: error.message,
      status: error.status,
    };
  }

  return error;
}

export function consoleLogError<T>(
  defaultMessage: string,
  response: any,
  additionalResponseData?: T
) {
  const error = getErrorMessage(defaultMessage, response);

  if (typeof response === "object" && "stack" in response) {
    console.error(response.stack);
  }

  if (process.env.NODE_ENV !== "test") {
    console.error(
      "\n\n🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨🚨\n\nERROR:",
      error,
      "\n\n",
      "response:",
      JSON.stringify(getResponseObject(response), null, 2),
      "\n\n",
      "additionalResponseData:",
      JSON.stringify(additionalResponseData, null, 2),
      "\n\n"
    );
  }
}

export function responseErrorData(defaultErrorMessage: string, error: Response) {
  const errorMessage = getErrorMessage(defaultErrorMessage, error);
  const status = getErrorStatus(error);

  return {
    errorMessage,
    status,
  };
}

export const responseError = <T extends object>(
  defaultErrorMessage: string,
  response: any,
  additionalResponseData?: T
) => {
  void consoleLogError<T>(defaultErrorMessage, response, additionalResponseData);

  const { errorMessage, status } = responseErrorData(defaultErrorMessage, response);

  Sentry.captureMessage(errorMessage, {
    extra: {
      response,
      additionalResponseData,
      status,
    },
  });

  return json<ErrorResponse<T>>(
    {
      error: errorMessage,
      forceLogin: status === 401 ? true : undefined,
      ...additionalResponseData,
    } as ErrorResponse<T>,
    {
      status,
      ...additionalResponseData,
    }
  );
};
