function stringRequirement(value: any) {
  return typeof value === "string" ? value : undefined;
}

export function getErrorMessage(defaultMessage: string, error: any): string {
  return (
    stringRequirement(error.message?.detail) ||
    stringRequirement(error.message?.title) ||
    stringRequirement(error.message?.error) ||
    stringRequirement(error.detail) ||
    stringRequirement(error.title) ||
    stringRequirement(error.message) ||
    stringRequirement(error.error) ||
    stringRequirement(error.error?.detail) ||
    stringRequirement(error.error?.title) ||
    stringRequirement(error.error?.message) ||
    defaultMessage
  );
}
