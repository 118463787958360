export type ApiResponseError = {
  message: string;
  status: number;
  toString: () => string;
};

export default function createApiResponseError(message: string, status: number): ApiResponseError {
  return {
    message,
    status,
    toString: () => message,
  };
}

export function isApiResponseError(error?: any) {
  if (typeof error === "object") {
    return error && "status" in error && "message" in error;
  }

  return false;
}
